import React from "react";
import './styles/PastAchievements.css';
import PastAchievementCard from "../components/PastAchievementCard";
import iptaAchievements from "../assets/data/achievements/ipta";

function IPTAPage() {  
  return (
    <div className="past-achievements-body">
      <div className="past-achievements-main">
        <div className="past-achievements-header">
          Indian People's Theatre Association's <br/><span>Inter Collegiate Drama Competition</span>
        </div>
        
        <div className="past-achievements-text">
          {iptaAchievements.map((achievement) => (
            <PastAchievementCard
              key={achievement.name}
              name={achievement.name}
              items={achievement.items}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default IPTAPage;
