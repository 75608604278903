const teachers = {
  conveners: [
    { name: "Dr. Papiya Deb", position: "Convenor" },
    { name: "Dr. Jayshree Ravi", position: "Co-Convenor" },
  ],
  incharges: [
    "Mr. Saurabh Panchamia",
    "CA Ashish Garg",
  ],
  list: [
    "Mr. Omkar Mohite",
    "Mr. Shripad Samant",
    "Mr. Sagar Sonawane",
    "Ms. Antara Kawale",
    "CA Baijul Mehta",
    "Mr. Sacchidanand Singh",
    "Dr. Nilesh Korgavkar",
    "Mr. Shashwat Shetty",
    "Mr. Pravin More",
    "CA Swati Gupta",
    "Mr. Rakesh Panse",
    "Mr. Jaipalsing Girase",
    "Dr. Vaishnavi More",
    "Dr. Dipali Sapre",
    "Dr. Satish Ingale",
    "Ms. Bhavika Patni",
    "Dr. Rakhi Madnani",
    "Mr. Pradeep Sawant",
    "Ms. Nisha Singh",
  ]
};

export default teachers;