import React from "react";
import './AlumniCard.css'
import images from "../assets/images";

function AlumniCard({ items }){
  const getImage = (user) => {
    const key = user.name.toLowerCase().replace(/ /g, "");
    if(key === "parthshah"){
      if(user.year === "(2021)") return images["parthshah2021"];
      return images["parthshah2019"];
    }
    return images[key] || images["blank"];
  };

  return(
    items.map((alumni, index) => (
      <div className="alumni-card" key={index}>
        <a href={alumni.link} target="_blank" rel="noreferrer">
        <div className="alumni-photo"><img src={getImage(alumni)} alt={alumni.name}/></div>
        <div className="alumni-name">{alumni.name}</div>
        <div className="alumni-position">{alumni.position}</div>
        <div className="alumni-year">{alumni.year}</div>
        </a>
      </div>
    ))
  )
}

export default AlumniCard;