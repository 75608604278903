import React from "react";
import { Link } from "react-router-dom";
import instagram from "../assets/images/icons/instagram.png";
import threads from "../assets/images/icons/threads.png";
import facebook from "../assets/images/icons/facebook.png";
import linkedin from "../assets/images/icons/linkedin.png";
import youtube from "../assets/images/icons/youtube.png";
import "./Footer.css";

function Footer(){
  const profiles = [
    { link: "https://www.instagram.com/mithibaiculturalcommittee", photo: instagram, alt: "Instagram" },
    { link: "https://www.threads.net/@mithibaiculturalcommittee", photo: threads, alt: "Threads" },
    { link: "https://www.facebook.com/mithibaiculturalcommittee", photo: facebook, alt: "Facebook" },
    { link: "https://www.linkedin.com/company/mithibai-cultural-committee", photo: linkedin, alt: "LinkedIn" },
    { link: "https://www.youtube.com/@mithibaiculturalcommittee7736", photo: youtube, alt: "YouTube" },
  ]
  return(
    <div className="footer">
      <ul className="footer-menu">
        { profiles.map((profile) => (
          <li key={profile.alt} className="footer-item">
            <Link to={profile.link} target="_blank" rel="noopener noreferrer" className="footer-links">
              <img src={profile.photo} alt={profile.photo}></img>
            </Link>
          </li>
        ))}
      </ul>
      
      <div className="footer-copyright-parent">
        <div className="footer-copyright">© Mithibai Cultural Committee.</div>
        <div className="footer-copyright">All Rights Reserved.</div>
      </div>
    </div>
  );
}

export default Footer;  