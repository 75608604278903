// TeachersPage.js
import React from "react";
import './styles/TeachersPage.css';
import teachers from '../assets/data/teams/teachers';

function TeacherCard({ name, position }) {
  return (
    <div className="teacher-card-container">
      <div className="teacher-card-name">{name}</div>
      <div className="teacher-card-position">{position}</div>
    </div>
  );
}

function TeachersPage() {
  return (
    <div className="teachers-body">
      <div className="teachers-main">
        <div className="teachers-header">Our Teachers</div>

        <div className="teachers-convener">
          {teachers.conveners.map((convener, index) => (
            <TeacherCard
              key={`convener-${index}`}
              name={convener.name}
              position={convener.position}
            />
          ))}
        </div>

        <div className="teachers-incharge">
          {teachers.incharges.map((incharge, index) => (
            <TeacherCard
              key={`incharge-${index}`}
              name={incharge}
              position={"Teacher Coordinator"}
            />
          ))}
        </div>

        <div className="teachers-list">
          {teachers.list.map((teacher, index) => (
            <TeacherCard
              key={`teacher-${index}`}
              name={teacher}
              position={"Teacher In-Charge"}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default TeachersPage;
