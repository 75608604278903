import React from "react";
import { useParams } from "react-router-dom";
import "../components/PastAchievementCard.css";
import AchievementsDropdown from "../components/AchievementsDropdown";
import PastAchievementCard from "../components/PastAchievementCard";
import achievements from "../assets/data/achievements/yearwise";
import NotFoundPage from "./NotFoundPage";
import YouthPage from "./YouthPage";
import MoodIndigoPage from "./MoodIndigoPage";
import IPTAPage from "./IPTAPage";

function PastAchievementsPage() {
  const { year } = useParams();

  if(year === "youth") return <YouthPage/>
  if(year === "moodindigo") return <MoodIndigoPage/>
  if(year === "ipta") return <IPTAPage/>

  const achievementsForYear = achievements[year];
  if(!achievementsForYear) return <NotFoundPage/>;

  return (
    <div className="past-achievements-body">
      <div className="past-achievements-main">
        <div className="past-achievements-header">
          Achievements of {year}-{parseInt(year) + 1}
        </div>

        <div className="past-achievements-text">
          {achievementsForYear.map((achievement, index) => (
            <PastAchievementCard
              key={`${year}-${index}-${achievement.name}`}
              name={achievement.name}
              items={achievement.items}
            />
          ))}
        </div>

        <AchievementsDropdown showOverall={true} />
      </div>
    </div>
  );
}

export default PastAchievementsPage;