import React from "react";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import ATSPage from "./pages/ATSPage";
import KshitijPage from "./pages/KshitijPage";
import AlumniPage from "./pages/AlumniPage";
import AnnualDayPage from "./pages/AnnualDayPage";
import ContactPage from "./pages/ContactUsPage";
import OurTeamsPage from "./pages/OurTeamsPage";

import AchievementsPage from "./pages/AchievementsPage";
import PastAchievementsPage from "./pages/PastAchievementsPage";

import TeamPage from "./pages/TeamPage";
import PastTeamPage from "./pages/PastTeamPage";

import TeachersPage from "./pages/TeachersPage";
import GalleryPage from "./pages/GalleryPage";
import NotFoundPage from "./pages/NotFoundPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App(){
  return(
    <Router>
      <Navbar/>
      <Routes>
        <Route path="/">
          <Route index element={<HomePage/>}/>
          <Route path="about" element={<AboutPage/>}/>
          <Route path="ats" element={<ATSPage/>}/>
          <Route path="kshitij" element={<KshitijPage/>}/>
          <Route path="annual-day" element={<AnnualDayPage/>}/>
          <Route path="alumni" element={<AlumniPage/>}/>
          <Route path="contact" element={<ContactPage/>}/>
          <Route path="our-teams" element={<OurTeamsPage/>}/>

          <Route path="team/">
            <Route index element={<TeamPage/>}/>
            <Route path=":year" element={<PastTeamPage/>}/>
          </Route>

          <Route path="achievements/">
            <Route index element={<AchievementsPage/>}/>
            <Route path=":year" element={<PastAchievementsPage/>}/>
          </Route>

          <Route path="teachers" element={<TeachersPage/>}/>
          <Route path="gallery" element={<GalleryPage/>}/>
          <Route path="*" status={404} element={<NotFoundPage/>}/>
        </Route>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;