import React from "react";
import "./AchievementsDropdown.css";

function goto(){
  let page = document.getElementById('achievements-select').value;
  if(page === "") return;
  window.location.pathname = page;
}

function AchievementsDropdown({showOverall}){
  const years = [
    "2023", "2022", "2021", "2020", "2019", "2018",
    "2017", "2016", "2015", "2014", "2012", "2010",
    "2009", "2008", "2007", "2006"
  ];
  return (
    <div className="achievements-dropdown">
      <label htmlFor="achievements-select">Check out our yearly achievements!</label>
      <br/>
      <select name="achievements-select" id="achievements-select" onChange={goto}>  
        <option value="">Select Year</option>
        { showOverall && <option value="achievements/">Overall</option>}
        { years.map((year) => (
          <option key={year} value={`achievements/${year}`}>{year}</option>
        ))}
      </select>
    </div>
  );
}

export default AchievementsDropdown;