const achievements = {
  "2006": [
    {
      name: "University of Mumbai's 39th Youth Festival",
      items: [
        "1st Place - Classical Instrumental (Percussion)",
        "2nd Place - Classical Instrumental (Non-Percussion), Indian Group Singing",
      ],
    },
    {
      name: "Asia's Largest College Cultural Festival - 36th Mood Indigo",
      items: [
        "1st Place - Classical Instrumental (Percussion)",
        "Best Female Model",
        "2nd Place - Make a Band",
      ],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: [
        "Overall 2nd Runners Up",
        "Overall Fine Arts 1st Runners Up",
        "1st Place - Haunted House, War of Rappers",
      ],
    },
  ],

  "2007": [
    {
      name: "University of Mumbai's 40th Youth Festival",
      items: [
        "1st Place - Mono Acting (Sudeep Modak)",
        "2nd Place - Mono Acting (Shreya Bugde)",
        "3rd Place - Marathi One Act Play, Folk Dance",
      ],
    },
    {
      name: "Asia's Largest College Cultural Festival - 37th Mood Indigo",
      items: [
        "Mr. Mood Indigo - Ajit Nair",
        "Best Female Ensemble",
        "3rd Place - Fashion Team",
      ],
    },
    {
      name: "Indian People's Theatre Association",
      items: ["Best Actress - Shreya Bugde"],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: [
        "1st Place - Solo Dance (Noorin Shah)",
        "1st Place - Duet Singing",
        "2nd Place - War of Rappers (Aadir Malik)",
        "3rd Place - Western Dance",
      ],
    },
    {
      name: "St. Xaviers College's Malhar",
      items: [
        "Overall Fine Arts Trophy",
        "1st Place - Solo Singing (Annie Chawla)",
        "1st Place - Ekaanki",
      ],
    },
  ],

  "2008": [
    {
      name: "Asia's Largest College Cultural Festival - 38th Mood Indigo",
      items: ["Best Actor - Shreya Bugde"],
    },
    {
      name: "Indian People's Theatre Association",
      items: ["Best Play"],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: [
        "1st Place - Spoof",
        "2nd Place - Filmy Duet Singing",
        "3rd Place - Filmy Duet Dance, Hindi Band, Solo Classical Instrument",
      ],
    },
    {
      name: "St. Xaviers College's Malhar",
      items: [
        "2nd Place - Shadow Dance",
        "3rd Place - Bollywood Dance",
      ],
    },
  ],

  "2009": [
    {
      name: "University of Mumbai's 42nd Youth Festival",
      items: [
        "Best Actress - Shreya Bugde",
        "1st Place - Mono Acting, One Act Play",
        "2nd Place - Western Group Song, Indian Classical Instrumental",
        "3rd Place - Hindi Skit, Western Solo Singing, Indian Classical Dance",
      ],
    },
    {
      name: "Asia's Largest College Cultural Festival - 39th Mood Indigo",
      items: [
        "1st Place - Sufi Singing",
        "2nd Place - Mime, Street Play",
        "3rd Place - Street Dance, Shadow Dance",
      ],
    },
    {
      name: "Indian People's Theatre Association",
      items: [
        "Prithviraj Kapoor Trophy for Best Production - Lukka Chuppi",
        "Best Director - Abhijeet Khade",
        "Best Actor - Amatya Goradiya",
        "Balraj Sahni Award - Hardik Sangani",
      ],
    },
    {
      name: "St. Xaviers College's Malhar",
      items: [
        "1st Place - Carnatic Singing",
        "2nd Place - Instrumental",
        "3rd Place - Miss Malhar",
      ],
    },
  ],

  // ... Continue adding data for years 2010 to 2023 similarly

  "2010": [
    {
      name: "University of Mumbai's 43rd Youth Festival",
      items: [
        "Best Play",
        "Best Female Actor",
        "2nd Place - Best Male Actor",
      ],
    },
  ],

  "2012": [
    {
      name: "University of Mumbai's 45th Youth Festival",
      items: [
        "Best Actor - Hardik Sangani",
        "Best Actress - Palak Shah",
        "1st Place - Hindi One Act Play, Mono Acting, Poster Making, On the Spot Painting",
        "2nd Place - Clay Modelling, Rangoli, Marathi Debate",
        "3rd Place - Indian Folk Dance, Cartoonist",
      ],
    },
  ],

  "2014": [
    {
      name: "University of Mumbai's 47th Youth Festival",
      items: [
        "1st Place - Folk Orchestra, Poster Making, One Act Play, Western Instrumental, Western Solo",
        "2nd Place - Western Group Song, Collage, Clay Modelling, Skit",
        "3rd Place - Installation, Indian Group Song",
        "Overall Fine Arts Trophy",
        "Moniraj Hazarika - awarded the Golden Boy",
      ],
    },
    {
      name: "Asia's Largest College Cultural Festival - 44th Mood Indigo",
      items: [
        "Overall 1st Runners Up",
        "Overall Music Trophy",
      ],
    },
    {
      name: "Indian People's Theatre Association",
      items: [
        "Pritviraj Chauhan Trophy",
        "A.K. Hangal Trophy",
        "Best Actor",
        "Best Actress",
        "Best Writer",
      ],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: ["Overall Champions"],
    },
  ],

  "2015": [
    {
      name: "Mumbai University's Youth Festival",
      items: [
        "Overall Winners",
        "1st Place - Indian Group Singing, Western Group Singing, Western Instrumental, English Elocution",
        "1st Place - Collage, Cartoonist, Marathi One Act, Best Actor",
        "2nd Place - Western Solo Singing, Indian Light Vocal, Folk Dance, Hindi Skit",
        "3rd Place - Marathi Mono Acting, Indian Classical, Clay Modelling",
      ],
    },
    {
      name: "Asia's Largest College Cultural Festival - 45th Mood Indigo",
      items: ["Overall 1st Runners Up", "Overall Music Trophy"],
    },
    {
      name: "Indian People's Theatre Association",
      items: [
        "Prithviraj Kapoor Trophy for Best Production",
        "A.K. Hangal Trophy for Critic Awards",
        "Best Actor",
        "Best Actress",
        "Best Writer",
      ],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: ["Overall 1st Runners Up"],
    },
    {
      name: "Thakur College of Science and Commerce's Taarangan",
      items: ["1st Runner Up"],
    },
  ],

  "2016": [
    {
      name: "University of Mumbai's 49th Youth Festival",
      items: [
        "Zonal Champions",
        "1st Place - Western Solo Singing, Light Vocal Music, Poster Making, Rangoli, Folk Orchestra",
      ],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: [
        "1st Place - Fashion Show, Best Writer, Best Director, Best Play",
      ],
    },
    {
      name: "St. Xaviers College's Malhar",
      items: ["1st Runners Up"],
    },
  ],

  "2017": [
    {
      name: "Asia's Largest College Cultural Festival - 47th Mood Indigo",
      items: [
        "1st Runners Up",
        "1st Place - Fashion Show (Padmini Chundawat)",
      ],
    },
  ],

  "2018": [
    {
      name: "University of Mumbai's 51st Youth Festival",
      items: [
        "1st Place - Folk Orchestra, Qawali, Mehendi",
        "2nd Place - Western Group Singing, Western Instrumental, Western Solo Singing",
        "3rd Place - Indian Group Singing, Classical Vocal Solo, Light Vocal Solo, Clay Modelling",
      ],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: ["Overall Runners Up"],
    },
    {
      name: "St. Xaviers College's Malhar",
      items: ["Overall Runners Up"],
    },
  ],

  "2019": [
    {
      name: "University of Mumbai's 52nd Youth Festival",
      items: ["Overall 2nd Runners Up"],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: ["Overall Runners Up", "Gaming and Sports Departmental Trophy"],
    },
    {
      name: "St. Xaviers College's Malhar",
      items: ["Overall 2nd Runners Up"],
    },
    {
      name: "Sophia College's Kaleidoscope",
      items: ["Overall 2nd Runners Up"],
    },
    {
      name: "Thakur College of Science and Commerce's Taarangan",
      items: ["Overall Winners"],
    },
    {
      name: "NMIMS Anil Surendra Modi School of Commerce's Vaayu",
      items: ["Overall Winners"],
    },
    {
      name: "RedBull Campus Clutch - Global Student Valorant Tournament",
      items: [
        "Overall Champions - Mumbai",
        "National Quarter Finalists - India Top 16",
      ],
    },
    {
      name: "Jai Hind College's Shoutt",
      items: ["Overall Second Runners Up"],
    },
    {
      name: "Jai Hind College's Finance Festival Entourage",
      items: ["Overall Champions"],
    },
    {
      name: "SVKM's Pravin Gandhi College of Law's Vividh",
      items: ["Overall Runners Up"],
    },
  ],

  "2020": [
    {
      name: "University of Mumbai's 53rd Youth Festival",
      items: [
        "Zonal Champions for the 3rd year in a row",
        "Overall Runners Up",
      ],
    },
    {
      name: "Asia's Largest College Cultural Festival - 50th Mood Indigo",
      items: ["Overall Champions"],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: ["Overall Champions after 8 years"],
    },
    {
      name: "BITS Pilani's Oasis",
      items: ["Overall Runners Up"],
    },
    {
      name: "Wilson College's Olé",
      items: ["Overall Champions"],
    },
    {
      name: "NMIMS Centre for International Studies' Zion",
      items: ["Overall Champions"],
    },
    {
      name: "SVKM's Pravin Gandhi College of Law's Vividh",
      items: ["Overall Champions"],
    },
    {
      name: "HR College's Off D'Cuff",
      items: ["Overall Winners"],
    },
    {
      name: "HR College's Literary Arts Festival Litfest",
      items: ["Overall Champions"],
    },
  ],

  "2021": [
    {
      name: "University of Mumbai's 54th Youth Festival",
      items: [
        "Overall 2nd Runners Up",
        "Zonal Champions",
      ],
    },
    {
      name: "Asia's Largest College Cultural Festival - 51st Mood Indigo",
      items: ["Overall Champions"],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: [
        "Overall Second Runners Up",
        "Best Contingent Leader - Parth Shah",
      ],
    },
    {
      name: "St. Xaviers College's Malhar",
      items: ["Overall Runners Up"],
    },
    {
      name: "BITS Goa's Waves",
      items: ["Overall Champions"],
    },
    {
      name: "Jai Hind College's Shoutt",
      items: ["Overall Winners"],
    },
    {
      name: "Kishinchand Chellaram College's Kiran",
      items: ["2nd Runners Up"],
    },
    {
      name: "Thakur College of Science and Commerce's Taarangan",
      items: ["Overall Winners"],
    },
    {
      name: "R. A. Poddar's Enigma",
      items: ["Overall Runners Up"],
    },
    {
      name: "Hassaram Rijhumal College's Mystique",
      items: ["1st Runners Up"],
    },
    {
      name: "HR College's Off D'Cuff",
      items: ["Overall Winners"],
    },
    {
      name: "SVKM's Narsee Monjee College's Academic Festival Arthashastra",
      items: ["2nd Runners Up"],
    },
    {
      name: "SVKM's Pravin Gandhi College of Law's Vividh",
      items: ["1st Runners Up"],
    },
  ],

  "2022": [
    {
      name: "University of Mumbai's 55th Youth Festival",
      items: [
        "Overall Runners Up",
        "Zonal Champions for 5th Consecutive Year",
        "Garware Trophy for Debate Hindi/English Gold",
        "Mr. University after 25 Years - Mr. Krishna Birla",
        "Ms. University Runners Up - Ms. Suhani Panchal",
      ],
    },
    {
      name: "Asia's Largest College Cultural Festival - 52nd Mood Indigo",
      items: [
        "Overall Champions 3rd Time Consecutively",
        "Mumbai Multicity Champion",
        "Campus Ambassadorship Champion - Mr. Krishna Birla",
      ],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: [
        "Overall Runners Up",
        "Best Contingent Leader - Mr. Ojasvee Singhal",
      ],
    },
    {
      name: "NMIMS Anil Surendra Modi School of Commerce's Vaayu",
      items: [
        "Overall Champions",
        "Innovations Department Champions",
      ],
    },
    {
      name: "Atlas Skilltech University ISME's Illenium",
      items: [
        "Overall Runners Up",
        "Literary Arts Department Champions",
        "Fine Arts Department Champions",
      ],
    },
    {
      name: "Viral Fission's Collide",
      items: [
        "Overall Champions",
        "Best Contingent Leader Runners Up - Ms. Rishika Mukherjee",
        "Best Assistant Contingent Leader Runners Up - Ms. Yashvi Chheda",
      ],
    },
    {
      name: "Thakur College of Science and Commerce's Taarangan",
      items: ["Overall Champions"],
    },
    {
      name: "Ramniranjan Anandilal Poddar's Enigma",
      items: ["Overall Champions"],
    },
    {
      name: "RedBull Campus Clutch - Global Student Valorant Tournament",
      items: [
        "Overall Champions - Mumbai",
        "National Quarter Finalists - India Top 16",
      ],
    },
    {
      name: "Jai Hind College's Shoutt",
      items: ["Overall Second Runners Up"],
    },
    {
      name: "Jai Hind College's Finance Festival Entourage",
      items: ["Overall Champions"],
    },
    {
      name: "SVKM's Pravin Gandhi College of Law's Vividh",
      items: ["Overall Runners Up"],
    },
  ],

  "2023": [
    {
      name: "University of Mumbai's 56th Youth Festival",
      items: [
        "Overall Runners Up",
        "Zonal Champions for 6th Consecutive Year",
        "Short Film Gold Medalist after Debut",
      ],
    },
    {
      name: "Asia's Largest College Cultural Festival - 53rd Mood Indigo",
      items: ["Overall Champions", "Mumbai Multicity Champions"],
    },
    {
      name: "Indian People's Theatre Association - 50th Inter Collegiate Drama Competition [Golden Jubilee Edition]",
      items: [
        "Manmohan Krishna Trophy for 2nd Best Production - Baram",
        "Avtar Kishan Hangal Trophy for Best Actor - Mr. Lavish Jain",
        "Mysore Shrinivas Sathyu Trophy for Best Set Design - Mr. Yash Pawar [Debut Award]",
        "Merit Certificates for Best Actress, Best Teamwork, Best Directors, Best Lights",
      ],
    },
    {
      name: "SVKM's Narsee Monjee College's Umang",
      items: [
        "Overall 1st Runners Up",
        "Business Events Departmental Trophy",
      ],
    },
    {
      name: "NMIMS Anil Surendra Modi School of Commerce's Vaayu",
      items: ["Overall 1st Runners Up"],
    },
    {
      name: "Viral Fission's Collide",
      items: ["Best Contingent - ViFi Integration"],
    },
    {
      name: "NMIMS School of Branding and Advertising's Rivaya",
      items: [
        "Overall Champions",
        "Best Contingent Leader - Mr. Viraj Tanna",
        "Fine Arts Departmental Trophy",
        "Business Administration Departmental Trophy",
        "Branding Advertisement Departmental Trophy",
        "Performing Arts Departmental Trophy",
      ],
    },
    {
      name: "World Forum for Art and Culture's National Level Youth Festival - Jhankriti",
      items: [
        "1st Place - Mono Acting",
        "2nd Place - Group Folk Dance",
        "3rd Place - Group Drama",
      ],
    },
    {
      name: "HSNC University's Idalia",
      items: ["Overall Champions"],
    },
    {
      name: "Hassaram Rijhumal College's Mystique",
      items: [
        "Overall 3rd Runners Up",
        "Gaming and Sports Departmental Trophy",
      ],
    },
    {
      name: "Event Manager of the Year Award by NIEM India",
      items: ["Mr. Krishna Birla", "Mr. Shivam Jhawar"],
    },
    {
      name: "Jai Hind College's Shoutt",
      items: [
        "Overall 3rd Runners Up",
        "Business Events Departmental Trophy",
        "Informals Departmental Trophy",
      ],
    },
    {
      name: "Jai Hind College's Financial Festival Entourage",
      items: ["Overall 2nd Runners Up"],
    },
    {
      name: "Ramniranjan Anandilal Poddar's Enigma",
      items: ["[Half Contingent]", "Business Events Departmental Trophy"],
    },
    {
      name: "National Institute of Event Management's NIEM Extravaganza",
      items: [
        "Overall Champions",
        "Best Student Representative - Mr. Krishna Birla, Mr. Shivam Jhawar",
      ],
    },
    {
      name: "Hassaram Rijhumal College's Off D'Cuff",
      items: ["Overall Champions"],
    },
    {
      name: "SVKM's Narsee Monjee College's Management and Strategy Festival Arithmos",
      items: ["Overall 1st Runners Up"],
    },
    {
      name: "Kalagurjari - Vijayi Smruti Ekanki Spardha",
      items: [
        "Overall Best Play - Picture Abhi Baki Hai",
        "Overall Best Writer - Mr. Dheer Shah",
        "Overall Best Director - Mr. Dheer Shah",
        "Overall Best Actress - Ms. Khushi Acharya",
      ],
    },
  ],
};

export default achievements;