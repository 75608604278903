import React from "react";
import './styles/NotFoundPage.css'

function AchievementsPage() {  
  return(
    <div className = "not-found-body">
      <div className="not-found-main">
        <div className="not-found-header">Sorry!</div>
        <div className="not-found-text">
          You didn't break the website,<br/> but we can't find what you're looking for.
        </div>
      </div>
    </div>
  )
};

export default AchievementsPage;
