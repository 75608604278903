import React from "react";
import { useParams } from "react-router-dom";
import "./styles/PastTeam.css"; 
import TextCard from "../components/TextCard";
import TeamDropdown from "../components/TeamDropdown";
import teamData from "../assets/data/teams/yearwise";
import NotFoundPage from "./NotFoundPage";

function PastTeamPage() {
  const { year } = useParams();
  
  const teamForYear = teamData[year];
  if(!teamForYear) return <NotFoundPage/>;

  return (
    <div className="past-team-body">
      <div className="past-team-main">
        {teamForYear ? (
          <>
            <div className="past-team-header">{teamForYear.yearRange}</div>

            {teamForYear.leaders && teamForYear.leaders.length > 0 && (
              <>
                <div className="past-team-coordinators">
                  {teamForYear.leaders.map((leader, index) => (
                    <TextCard
                      key={`leader-${index}-${leader.name}`}
                      className="topcore-card"
                      name={leader.name}
                      position={leader.position}
                    />
                  ))}
                </div>
              </>
            )}

            {teamForYear.admins && teamForYear.admins.length > 0 && (
              <>
                <div className="past-team-header"> Admin Team </div>
                <div className="past-team-admin-heads">
                  {teamForYear.admins.map((admin, index) => (
                    <TextCard
                      key={`admin-${index}-${admin.name}`}
                      name={admin.name}
                      position={admin.position}
                    />
                  ))}
                </div>
              </>
            )}

            {teamForYear.generalCoordinators && teamForYear.generalCoordinators.length > 0 && (
              <>
                <div className="past-team-header"> General Coordinators </div>
                <div className="past-team-coordinators">
                  {teamForYear.generalCoordinators.map((gc, index) => (
                    <TextCard
                      key={`gc-${index}-${gc.name}`}
                      name={gc.name}
                      position={gc.position}
                    />
                  ))}
                </div>
              </>
            )}
          </>
        ) : (
          <div className="no-data-message">
            <h2>No Team Data Available for the Year {year}</h2>
            <p>Please select a valid year from the dropdown below.</p>
          </div>
        )}
      </div>
      <TeamDropdown showCurrent={true}/>
    </div>
  );
}

export default PastTeamPage;