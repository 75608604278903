import React from "react";
import './styles/PastAchievements.css';
import PastAchievementCard from "../components/PastAchievementCard";
import moodIndigoAchievements from "../assets/data/achievements/moodindigo";

function MoodIndigoPage() {  
  return (
    <div className="past-achievements-body">
      <div className="past-achievements-main">
        <div className="past-achievements-header">
          IIT Bombay's Mood Indigo
        </div>
        
        <div className="past-achievements-text">
          {moodIndigoAchievements.map((edition) => (
            <PastAchievementCard
              key={edition.name}
              name={edition.name}
              items={edition.items}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default MoodIndigoPage;
