const noteableAlumni = [
  { name: "Vivek Oberoi", position: "Actor", link: "https://www.instagram.com/vivekoberoi", year: "(1997)" },
  { name: "Shreyas Talpade", position: "Actor", link: "https://www.instagram.com/shreyastalpade27", year: "(1995)" },
  { name: "Siddhant Chaturvedi", position: "Actor", link: "https://www.instagram.com/siddhantchaturvedi", year: "(2012)" },
  { name: "Abhinay Berde", position: "Actor", link: "https://www.instagram.com/abhinay3", year: "(2018)" },
  { name: "Sukriti Kakar", position: "Singer", link: "https://www.instagram.com/sukritikakar", year: "(2013)" },
  { name: "Prakriti Kakar", position: "Singer", link: "https://www.instagram.com/prakritikakar", year: "(2013)" },
  { name: "Bhavya Gandhi", position: "Actor", link: "https://www.instagram.com/bhavyagandhi97", year: "(2018)" },
  { name: "Maanuni Desai", position: "Singer", link: "https://www.instagram.com/scarlettvictim", year: "(2017)" },
  { name: "Shreya Bugde", position: "Actress", link: "https://www.instagram.com/shreyabugde", year: "(2007)" },
  { name: "Parvathy Omanakuttan", position: "Dancer", link: "https://www.instagram.com/parvathyo13", year: "(2008)" },
  { name: "Shivani Singh", position: "Miss Diva Universe 2014", link: "https://www.instagram.com/shivani.singhh/", year: "(2017)" },
  { name: "Amatya Goradia", position: "Actor", link: "https://www.instagram.com/amatyawrites", year: "(2011)" },
  { name: "Rhea Borah", position: "Model", link: "https://www.instagram.com/rheab73_", year: "(2021)" },
  { name: "Aasa Singh", position: "Musician", link: "https://www.instagram.com/aasa.sing", year: "" },
  { name: "Moniraj Hazarika", position: "Musician", link: "https://www.instagram.com/monirajhazarika", year: "(2016)" },
];

export default noteableAlumni;
