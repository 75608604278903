// src/assets/data/team.js

const teamData = {
  "2023": {
    yearRange: "2023-24",
    leaders: [
      { name: "Krishna Birla", position: "Cultural Secretary" },
      { name: "Shivam Jhawar", position: "Deputy Cultural Secretary" },
    ],
    admins: [
      { name: "Chehal Agrawal", position: "Admin Head" },
      { name: "Kaavya Dhoot", position: "Admin Head" },
      { name: "Bansi Patel", position: "Admin Head" },
      { name: "Aisha Taurani", position: "Admin Head" },
      { name: "Saniya Walavalkar", position: "Admin Head" },
      { name: "Soham Tatar", position: "Admin Head" },
    ],
    generalCoordinators: [
      { name: "Jalp Vahaluwala", position: "Dance" },
      { name: "Parva Vithlani", position: "Dance" },
      { name: "Mayank Jhakaria", position: "Dance" },
      { name: "Pranav Shetye", position: "Drama" },
      { name: "Khushi Acharya", position: "Drama" },
      { name: "Dhriti Bagle", position: "Fashion" },
      { name: "Manya Chauhan", position: "Fashion" },
      { name: "Pranjay Ghiya", position: "Fashion" },
      { name: "Janhavi Bhagwat", position: "Music" },
      { name: "Aakash Rai", position: "Music" },
      { name: "Gaurav Mehra", position: "Gaming and Sports" },
      { name: "Daksh Nehra", position: "Gaming and Sports" },
      { name: "Suhani Panchal", position: "Literary Arts" },
      { name: "Dishi Mehta", position: "Literary Arts" },
      { name: "Rachel Prakash", position: "Fine Arts" },
      { name: "Ishaan Choudhary", position: "Fine Arts" },
      { name: "Rutvi Patel", position: "Fine Arts" },
      { name: "Manan Pareek", position: "Photomedia" },
      { name: "Harsh Mudliar", position: "Photomedia" },
      { name: "Aditya Pandey", position: "Photomedia" },
      { name: "Manav Pradhan", position: "Photomedia" },
      { name: "Gaurav Mehra", position: "Website" },
      { name: "Vidhaan Dharia", position: "Technicals" },
      { name: "Shivansh Singh", position: "Social Media" },
      { name: "Shlok Sureka", position: "Business Events" },
    ],
  },

  "2022": {
    yearRange: "2022-23",
    leaders: [
      { name: "Ojasvee Singhal", position: "Cultural Secretary" },
    ],
    admins: [
      { name: "Yashvi Chheda", position: "Admin Head" },
      { name: "Vidhi Shah", position: "Admin Head" },
      { name: "Shivam Jhawar", position: "Admin Head" },
      { name: "Arushi Katyal", position: "Admin Head" },
      { name: "Krish Doshi", position: "Admin Head" },
      { name: "Vanshi Kamdar", position: "Admin Head" },
      { name: "Suhani Panchal", position: "Admin Head" },
      { name: "Krishna Birla", position: "Admin Head" },
      { name: "Sarthak Jain", position: "Admin Head" },
      { name: "Ishaan Choudhary", position: "Admin Head" },
      { name: "Aman Choudhary", position: "Admin Head" },
      { name: "Krishang Shah", position: "Admin Head" },
      { name: "Fiza Varia", position: "Admin Head" },
      { name: "Rishika Mukherjee", position: "Admin Head" },
      { name: "Neeti Singhania", position: "Admin Head" },
      { name: "Anandita Agarwal", position: "Admin Head" },
      { name: "Sudhanshu Agarwal", position: "Admin Head" },
      { name: "Manan Sheth", position: "Admin Head" },
    ],
    generalCoordinators: [
      { name: "Ayush Nair", position: "Literary Arts" },
      { name: "Amatulla Abdabhaiwala", position: "Literary Arts" },
      { name: "Isha Agarwal", position: "Literary Arts" },
      { name: "Rachel Prakash", position: "Fine Arts" },
      { name: "Zeal Dholakia", position: "Fine Arts" },
      { name: "Swadha Prasad", position: "Digital Media" },
      { name: "Shivansh Pandey", position: "Digital Media" },
      { name: "Parth Dholakia", position: "Business Events" },
      { name: "Poorvi Malpani", position: "Business Events" },
      { name: "Riddhish Hathi", position: "Music" },
      { name: "Krishna Solanki", position: "Music" },
      { name: "Suramya Pandya", position: "Music" },
      { name: "Kushagra Maheshwari", position: "Music" },
      { name: "Manan Pareek", position: "Photomedia" },
      { name: "Pooja Dani", position: "Photomedia" },
      { name: "Isha Agarwal", position: "Graphics" },
      { name: "Virendra Shah", position: "Productions and Documentation" },
      { name: "Saloni Shah", position: "Fashion" },
      { name: "Hridaya Mehta", position: "Fashion" },
      { name: "Jia Patel", position: "Fashion" },
      { name: "Vrishi Gandhi", position: "Drama" },
      { name: "Vaishnavi Singh", position: "Drama" },
      { name: "Shlok Bafna", position: "Dance" },
      { name: "Saasha Shetty", position: "Dance" },
      { name: "Rhushikesh Gujjar", position: "Gaming and Sports" },
      { name: "Moiz Shah", position: "Technicals" },
      { name: "Vidhaan Dharia", position: "Technicals" },
    ],
  },

  "2021": {
    yearRange: "2021-22",
    leaders: [
      { name: "Parth Shah", position: "Cultural Secretary" },
      { name: "Kunal Naker", position: "Joint Cultural Secretary" },
    ],
    admins: [
      { name: "Ojasvee Singhal", position: "Admin Head" },
      { name: "Praneet Parvataneni", position: "Admin Head" },
      { name: "Saurav Kothari", position: "Admin Head" },
      { name: "Sarthak Jain", position: "Admin Head" },
      { name: "Parth Dholakia", position: "Admin Head" },
      { name: "Mahika Bhojani", position: "Admin Head" },
      { name: "Ayush Nair", position: "Admin Head" },
      { name: "Krishang Shah", position: "Admin Head" },
      { name: "Vaibhav Parakh", position: "Admin Head" },
      { name: "Shubham Ridhaan", position: "Admin Head" },
      { name: "Abhigya Das", position: "Admin Head" },
      { name: "Anandita Agarwal", position: "Admin Head" },
      { name: "Fiza Varia", position: "Admin Head" },
    ],
    generalCoordinators: [
      { name: "Aishwarya Rathod", position: "Dance" },
      { name: "Sumeet Ramesh", position: "Dance" },
      { name: "Tanishq Jain", position: "Gaming and Sports" },
      { name: "Harshit Parmar", position: "Technicals" },
      { name: "Isha Agarwal", position: "Graphics" },
      { name: "Nikit Soni", position: "Graphics" },
      { name: "Shruti Gaonkar", position: "Drama" },
      { name: "Akhil Badheka", position: "Drama" },
      { name: "Riddhish Hathi", position: "Music" },
      { name: "Kaval Joshi", position: "Music" },
      { name: "Kreesha Thakkar", position: "Fashion" },
      { name: "Arshaan Hafiz", position: "Fashion" },
      { name: "Rachel Prakash", position: "Fine Arts" },
      { name: "Shriya Mehta", position: "Fine Arts" },
      { name: "Yogeshwar Singh", position: "Literary Arts" },
      { name: "Amatulla Abdabhaiwala", position: "Literary Arts" },
      { name: "Twisha Soni", position: "Literary Arts" },
      { name: "Swadha Prasad", position: "Digital Media" },
      { name: "Yash Shah", position: "Business Events" },
    ],
  },

  "2020": {
    yearRange: "2020-21",
    leaders: [
      { name: "Aditya Birawat", position: "Cultural Secretary" },
    ],
    admins: [],
    generalCoordinators: [
      { name: "Anurag Mali", position: "Gaming and Sports" },
      { name: "Parth Shah", position: "Gaming and Sports" },
      { name: "Shatakshi", position: "Media and Graphics" },
      { name: "Zoheb Karmali", position: "Media and Graphics" },
      { name: "Riddhish Hathi", position: "Music" },
      { name: "Abhiraj Dixit", position: "Music" },
      { name: "Tanuj Visaria", position: "Corporate Events" },
      { name: "Yashas Ruparelia", position: "Corporate Events" },
      { name: "Arshaan Hafiz", position: "Fashion" },
      { name: "Fiza Khan", position: "Fashion" },
      { name: "Hetvi Oza", position: "Fine Arts" },
      { name: "Anshika Chaudhary", position: "Fine Arts" },
      { name: "Sachi Desai", position: "Drama" },
      { name: "Dimple Sumbad", position: "Drama" },
      { name: "Yogeshwar Singh", position: "Literary Arts" },
      { name: "Twisha Soni", position: "Literary Arts" },
      { name: "Palak Panchal", position: "Dance" },
      { name: "Hardik Ruparel", position: "Dance" },
      { name: "Preksha Verma", position: "Dance" },
    ],
  },
  
};

export default teamData;