import React from "react";
import "./styles/AlumniPage.css";
import AlumniCard from "../components/AlumniCard";
import noteableAlumni from "../assets/data/alumni/notable";
import alumni from "../assets/data/alumni/yearwise";

function AlumniPage() {
  return (
    <div className="alumni-body">
      <div className="alumni-main">
        <div className="alumni-header">Our Alumni</div>
        <div className="alumni-text">
          <div className="notable-alumni">
            <div className="notable-alumni-header">Notable Alumni</div>
            <div className="notable-alumni-content">
              <AlumniCard items={noteableAlumni}/>
            </div>
          </div>
          <div className="notable-alumni">
            <div className="notable-alumni-header">Alumni</div>
            <div className="notable-alumni-content">
              <AlumniCard items={alumni}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlumniPage;
