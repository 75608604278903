import React from "react";
import './styles/PastAchievements.css';
import PastAchievementCard from "../components/PastAchievementCard";
import youthAchievements from "../assets/data/achievements/youth";

function YouthPage() {  
  return (
    <div className="past-achievements-body">
      <div className="past-achievements-main">
        <div className="past-achievements-header">
          University of Mumbai's Youth Festival
        </div>
        <div className="past-achievements-text">
          {youthAchievements.map((edition) => (
            <PastAchievementCard
              key={edition.name}
              name={edition.name}
              items={edition.items}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default YouthPage;
