const currentTeam = {
  leaders: [
    {
      name: "Aisha Taurani",
      position: "Cultural Committee Leader",
      link: "https://www.linkedin.com/in/aisha-taurani-251a9b246/",
    },
  ],
  admins: [
    {
      name: "Vrushti Shah",
      position: "Senior Admin Head",
      link: "https://www.instagram.com/vrushti_2603/",
    },
    {
      name: "Soham Tatar",
      position: "Senior Admin Head",
      link: "https://www.linkedin.com/in/sohamtatar",
    },
    {
      name: "Riddhi Gopalani",
      position: "",
      link: "https://www.instagram.com/riddhigopalanii/",
    },
    {
      name: "Bhavya Sharda",
      position: "",
      link: "https://www.linkedin.com/in/bhavyasharda29/",
    },
    {
      name: "Varang Malpani",
      position: "",
      link: "https://www.linkedin.com/in/varang-malpani/",
    },
    {
      name: "Namami Jariwala",
      position: "",
      link: "https://www.instagram.com/namamiii.10/",
    },
    {
      name: "Aman Rana",
      position: "",
      link: "https://www.instagram.com/am4n.5/",
    },
    {
      name: "Vanshika Gupta",
      position: "",
      link: "https://www.linkedin.com/in/vanshika-gupta-4a9886288/",
    },
    {
      name: "Lubhawani Singhal",
      position: "",
      link: "https://www.linkedin.com/in/lubhawani-singhal-4a6a0028b/",
    },
    {
      name: "Mahak Agrawal",
      position: "",
      link: "https://www.instagram.com/mahaak.7/"
    },
    {
      name: "Anushka Mohanty",
      position: "",
      link: "https://www.linkedin.com/in/anushka-mohanty-469ba6278/",
    },
    {
      name: "Paritosh Wadhwani",
      position: "",
      link: "https://www.instagram.com/paritoshwadhwani/",
    },
    {
      name: "Preet Jhajaria",
      position: "",
      link: "https://www.instagram.com/preetjhajaria/",
    },
    {
      name: "Pratham Chordiya",
      position: "",
      link: "https://www.instagram.com/pratham_chordiya22/",
    },
    {
      name: "Varinda Yadav",
      position: "",
      link: "https://www.instagram.com/varinda.22/",
    },
    {
      name: "Raashi Rakhasia",
      position: "",
      link: "https://www.instagram.com/raaashiiiiiii/",
    },
    {
      name: "Harshal Rajawat",
      position: "",
      link: "https://www.linkedin.com/in/harshal-rajawat-872004256/",
    },
    {
      name: "Shanvi Suhane",
      position: "",
      link: "https://in.linkedin.com/in/shanvi-suhane-448a2128b/",
    },
  ],
  generalCoordinators: [
    {
      name: "Varang Malpani",
      position: "Gaming and Sports",
      link: "https://www.linkedin.com/in/varang-malpani/",
    },
    {
      name: "Riddhi Gopalani",
      position: "Gaming and Sports",
      link: "https://www.instagram.com/riddhigopalanii/",
    },
    {
      name: "Aditya Pandey",
      position: "Photomedia",
      link: "https://www.instagram.com/aditya_p.21/",
    },
    {
      name: "Manav Pradhan",
      position: "Photomedia",
      link: "https://www.instagram.com/manavpradhan/",
    },
    {
      name: "Krit Chadha",
      position: "Photomedia",
      link: "https://www.instagram.com/kritchadha/",
    },
    {
      name: "Gaurav Mehra",
      position: "Website",
      link: "https://www.linkedin.com/in/mehra-gaurav/",
    },
    {
      name: "Anugya Parakh",
      position: "Digital Media",
      link: "https://www.instagram.com/anugya_parakh/",
    },
    {
      name: "Rida Sonawane",
      position: "Digital Media",
    },
    {
      name: "Raahi Doshi",
      position: "Digital Media",
      link: "https://www.instagram.com/raahidoshi/",
    },
    {
      name: "Janhavi Bhagwat",
      position: "Music",
      link: "https://www.instagram.com/musical.jenb/",
    },
    {
      name: "Aakash Rai",
      position: "Music",
      link: "https://www.instagram.com/aakash._.rai/",
    },
    {
      name: "Shivya Sharma",
      position: "Music",
      link: "https://www.instagram.com/shivyasharmaaa",
    },
    {
      name: "Parva Vithlani",
      position: "Dance",
      link: "https://www.instagram.com/parva.exe/",
    },
    {
      name: "Nehal Jain",
      position: "Dance",
      link: "https://www.instagram.com/_nehal.jain/",
    },
    {
      name: "Varinda Yadav",
      position: "Dance",
      link: "https://www.instagram.com/varinda.22/",
    },
    {
      name: "Saiyam Jain",
      position: "Drama",
      link: "https://www.instagram.com/saiyam1301/",
    },
    {
      name: "Ayushmaan Bhattacharya",
      position: "Drama",
      link: "https://www.instagram.com/ayushmaan0425/",
    },
    {
      name: "Soham Tatar",
      position: "Technicals",
      link: "https://www.linkedin.com/in/sohamtatar",
    },
    {
      name: "Khushi Jogi",
      position: "Fashion",
      link: "https://www.instagram.com/khushijogii/",
    },
    {
      name: "Priyanshi Poddar",
      position: "Fashion",
      link: "https://www.instagram.com/oh_itspriyanshi/",
    },
    {
      name: "Sarthak Choudhary",
      position: "Fashion",
      link: "https://www.instagram.com/sarthak0107/",
    },
    {
      name: "Anushka Mohanty",
      position: "Literary Arts",
      link: "https://www.linkedin.com/in/anushka-mohanty-469ba6278/",
    },
    {
      name: "Krisha Dave",
      position: "Literary Arts",
      link: "https://www.instagram.com/krishakdave/",
    },
    {
      name: "Umasa Warsi",
      position: "Fine Arts",
      link: "https://www.instagram.com/zozoyazoe",
    },
    {
      name: "Khushi Singala",
      position: "Fine Arts",
      link: "https://www.instagram.com/khushi.__2406/",
    },
    {
      name: "Gautam Mehta",
      position: "Business Events",
      link: "https://www.instagram.com/_gautam02/",
    },
    {
      name: "Shlok Sureka",
      position: "Business Events",
      link: "https://www.instagram.com/shloksureka/",
    },
  ],
};

export default currentTeam;