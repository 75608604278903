const alumni = [
  // 2023
  { name: "Krishna Birla", position: "Cultural Secretary", link: "https://www.linkedin.com/in/krishnabirla26", year: "(2023)" },
  { name: "Shivam Jhawar", position: "Deputy Cultural Secretary", link: "https://www.linkedin.com/in/shivamjhawar", year: "(2023)" },
  { name: "Mohak Matkar", position: "Actress and Singer", link: "https://www.instagram.com/mohak_matkar", year: "(2023)" },
  { name: "Anushka Gite", position: "Actress", link: "https://www.instagram.com/anushkagite13", year: "(2023)" },
  { name: "Arya Potdar", position: "Trader and Investor", link: "https://www.instagram.com/arya.potdar", year: "(2023)" },
  { name: "Shlok Bafna", position: "Dancer", link: "https://www.instagram.com/ft.shlok", year: "(2023)" },

  // 2022
  { name: "Ojasvee Singhal", position: "Cultural Secretary", link: "https://www.instagram.com/ojasvee03", year: "(2022)" },
  { name: "Praneet Parvataneni", position: "", link: "https://www.instagram.com/praneet_par1012", year: "(2022)" },
  { name: "Tanishq Jain", position: "", link: "https://www.instagram.com/tanishq_jain10", year: "(2022)" },
  { name: "Riddhish Hathi", position: "Musician", link: "https://www.instagram.com/riddhish_music", year: "(2022)" },
  { name: "Krishna Solanki", position: "Musician", link: "https://www.instagram.com/kris_solanki", year: "(2022)" },
  { name: "Savni Bhatt", position: "Musician", link: "https://www.instagram.com/savnibhatt", year: "(2022)" },
  { name: "Fiza Varia", position: "Actor", link: "https://www.instagram.com/fiza_varia", year: "(2022)" },
  { name: "Nischal Sharma", position: "Emcee", link: "https://www.instagram.com/nischal_sharmaaa", year: "(2022)" },
  { name: "Samika Mittal", position: "Ms. University Runner Up", link: "https://www.instagram.com/samikamital", year: "(2022)" },
  { name: "Aishwarya Rathod", position: "Influencer", link: "https://www.instagram.com/aishwaryarathod_", year: "(2022)" },
  { name: "Kunal Verma", position: "Musician", link: "https://www.instagram.com/kunalverma__", year: "(2022)" },
  { name: "Yogeshwar Singh", position: "Speaker", link: "https://www.instagram.com/yogeshwar._", year: "(2022)" },

  // 2021
  { name: "Parth Shah", position: "Cultural Secretary", link: "https://www.instagram.com/pxrthshxh/", year: "(2021)" },
  { name: "Kunal Naker", position: "Joint Cultural Secretary", link: "https://www.instagram.com/meinkunalhai", year: "(2021)" },
  { name: "Swar Sharma", position: "Musician", link: "https://www.instagram.com/swarrattansharma", year: "(2021)" },
  { name: "Shivam Trivedi", position: "Musician", link: "https://www.instagram.com/shivamtrivedii", year: "(2021)" },
  { name: "Arshaan Hafiz", position: "Model", link: "https://www.instagram.com/arshaannnhafiz", year: "(2021)" },

  // 2020
  { name: "Aditya Birawat", position: "Cultural Secretary", link: "https://www.instagram.com/adityabirawat", year: "(2020)" },

  // 2019
  { name: "Parth Shah", position: "Cultural Secretary", link: "https://www.instagram.com/__parthshah_", year: "(2019)" },

  // 2018
  { name: "Mahima Seth", position: "Cultural Secretary", link: "https://www.instagram.com/mahimaseth_", year: "(2018)" },

  // 2017
  { name: "Sagar Rana", position: "Cultural Secretary", link: "https://www.instagram.com/sagarranaofficial", year: "(2017)" },
  { name: "Lijo Varghese", position: "Joint Cultural Secretary", link: "https://www.instagram.com/lijo.jojo", year: "(2017)" },

  // 2016
  { name: "Pranita Kanhai", position: "Cultural Secretary", link: "https://www.instagram.com/pranitakanhai", year: "(2016)" },
  { name: "Akshar Oza", position: "Musician", link: "https://www.instagram.com/aksharoza", year: "(2016)" },
  { name: "Chandralekha Mukherjee", position: "Actress", link: "https://www.instagram.com/chandralekhamukherjee", year: "(2016)" },

  // 2015
  { name: "Karan Thakur", position: "Cultural Secretary", year: "(2015)" },
  { name: "Prince George", position: "Musician", link: "https://www.instagram.com/princegeorgemusic14", year: "(2015)" },

  // 2014
  { name: "Anurag Jajodia", position: "Cultural Secretary", link: "https://www.instagram.com/iamanuragjajodia", year: "(2014)" },

  // 2013
  { name: "Pranav Bhatt", position: "Cultural Secretary", year: "(2013)" },
  { name: "Antara Kawale", position: "Actor turned Teacher", link: "https://www.instagram.com/antara_k12", year: "(2013)" },

  // 2012
  { name: "Anurag Shastri", position: "Cultural Secretary", link: "https://www.instagram.com/anuragshastri", year: "(2012)" },

  // 2011
  { name: "Mayur Rao", position: "Cultural Secretary", link: "https://www.instagram.com/mayurraomusic", year: "(2011)" },

  // 2010
  { name: "Siddhikesh Ghosalkar", position: "Cultural Secretary", link: "https://www.instagram.com/siddhikeshghosalkar", year: "(2010)" },

  // 2009
  { name: "Wrisha Dutta", position: "Cultural Secretary", link: "https://www.instagram.com/wrisha", year: "(2009)" },

  // 2008
  { name: "Hardik Sangani", position: "Actor", link: "https://www.instagram.com/hardiksangani", year: "(2008)" },

  // 2006
  { name: "Swapnil Ajgaonkar", position: "Actor", link: "https://www.instagram.com/swapniilsa", year: "(2006)" },

  // 1999
  { name: "Yogesh Pagare", position: "Actor", link: "https://www.instagram.com/yogeshvpagaare", year: "(1999)" },

  { name: "Anuj Danait", position: "Musician", link: "https://www.instagram.com/anujdanait", year: "" },
  { name: "Kevin Manjrekar", position: "Musician", link: "https://www.instagram.com/Iammanjee", year: "" },
  { name: "Sanjeevani", position: "Musician", link: "https://www.instagram.com/ninnion12", year: "" },
  { name: "Samarth Swarup", position: "Musician", link: "https://www.instagram.com/samarthswarup", year: "" },
  { name: "Anuradha Pal", position: "Musician", link: "https://www.instagram.com/anuradhapaltabla", year: "" },
];

export default alumni;