import React from "react";
import "./styles/TeamPage.css";
import Card from "../components/Card";
import TeamDropdown from "../components/TeamDropdown";
import images from "../assets/images";
import currentTeam from "../assets/data/teams/current";

function TeamPage() {
  const getImage = (user) => {
    const key = user.name.toLowerCase().replace(/ /g, "");
    return images[key] || images["blank"];
  };

  const { leaders, admins, generalCoordinators } = currentTeam;

  return (
    <div className="team-body">
      <div className="team-main">
        <div className="team-header team-main-header">Our Team</div>

        <div className="team-top-core">
          {leaders.map((leader, index) => (
            <Card
              key={`leader-${index}-${leader.name}`}
              photo={getImage(leader)}
              name={leader.name}
              position={leader.position}
              link={leader.link}
            />
          ))}
        </div>

        <div className="team-header"> Admin Heads </div>
        <div className="team-admin-heads">
          {admins.map((admin, index) => (
            <Card
              key={`admin-${index}-${admin.name}`}
              photo={getImage(admin)}
              name={admin.name}
              position={admin.position}
              link={admin.link}
            />
          ))}
        </div>

        <div className="team-header"> General Coordinators </div>
        <div className="team-coordinators">
          {generalCoordinators.map((gc, index) => (
            <Card
              key={`gc-${index}-${gc.name}`}
              photo={getImage(gc)}
              name={gc.name}
              position={gc.position}
              link={gc.link}
            />
          ))}
        </div>
      </div>
      <TeamDropdown showCurrent={false} />
    </div>
  );
}

export default TeamPage;
